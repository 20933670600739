import './GameStyle.css'
import {useContext, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShop, faTimes} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import ShopItem from "./shop/ShopItem";
import FieldItem from "../fields/FieldItem";

Modal.setAppElement('#root');

function GameWrapperComponent({DataContext}) {

    const {userDataC, setUserDataC} = useContext(DataContext);

    const [havePlants, setHavePlants] = useState(16);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const cellSomething = () => {
        if (havePlants > 0 && userDataC.energy > 0) {
            setHavePlants(havePlants => havePlants - 1)

            setUserDataC(prevUserDataC => ({
                ...prevUserDataC,
                energy: prevUserDataC.energy - 10,
                money: prevUserDataC.money + 1
            }));

        }
    }


    const cellSeeds = (seed) => {

        if (
            seed.count && seed.count > 0 &&
            userDataC.energy >= seed.takeEnergy
        ) {
            seed.count -= 1;

            setUserDataC(prevUserDataC => ({
                ...prevUserDataC,
                energy: prevUserDataC.energy - seed.takeEnergy,
                money: prevUserDataC.money + 1
            }));
        }
    }

    const buySeed = (seedObj, setSeed) => {
        if (seedObj.count > 0 && userDataC.energy >= 10 && userDataC.money >= seedObj.price) {

            const seeds = userDataC.seeds
            seeds.wheat++;

            setUserDataC(prevUserData => ({
                ...prevUserData,
                energy: userDataC.energy - 10,
                money: userDataC.money - 1,
                seeds: seeds
            }))
            setSeed(prevSeed => ({...prevSeed, count: prevSeed.count - 1}));


        }
    }


    return (
        <div className="game-box-wrapper">


            <div className='plant-item'>
                <img src="/images/game-logo-2.png" alt="" className='plant-item-img'/>
                <div className='plant-item-count'>{havePlants}</div>
            </div>

            {userDataC.seeds.map((seed, index) => {
                return (
                    <button key={index}
                            className='btn btn-info take-money-btn mt-20'
                            onClick={() => {
                                cellSeeds(seed)
                            }}>
                        {seed.type} ({seed.count})
                    </button>
                )
            })}

            <button
                className='btn btn-info take-money-btn mt-20'
                onClick={cellSomething}>
                Cell something
            </button>


            <button className='btn btn-success take-money-btn mt-20' onClick={() => openModal()}>
                Shop <FontAwesomeIcon icon={faShop}/>
            </button>


            <div className='fields'>
                {userDataC.fileds.map(fieldItem => {
                    return (
                        <FieldItem ele={fieldItem} userData={userDataC} key={fieldItem.id}/>
                    )
                })}

            </div>


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-header">
                    <FontAwesomeIcon icon={faTimes} className='close-modal-btn' onClick={closeModal}/>
                    <h2>Shop</h2>
                </div>

                <div className="modal-content">

                    {userDataC.shop.map((ele, i) => {

                        return (
                            <ShopItem ele={ele} key={i} buySeed={buySeed}/>
                        )

                    })}

                </div>

            </Modal>
        </div>
    )

}

export default GameWrapperComponent