import './Auth.css'
import {useNavigate} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {setUserData} from "../../redux/actions";
import {useState} from "react";

import axios from "axios";
import {useDispatch} from "react-redux";
import ErrorHelper from "../../helpers/ErrorHelper";

function SignIn() {

    const errorHelper = new ErrorHelper();

    const [failRegistered, setFailRegistered] = useState(false)
    const [failRegisteredMessage, setFailRegisteredMessage] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const initialValues = {
        email: '',
        password: '',
    }

    const goToHome = () => {
        // Use navigate to redirect to a different route
        navigate('/game');
    };

    const validate = values => {

        const errors = {};


        // if (!values.email) {
        //     errors.email = 'The field Email is required!';
        // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        //     errors.email = 'Wrong email format!';
        // }
        //
        // if (!values.password) {
        //     errors.password = 'The field Password is required!';
        // }

        return errors;
    };

    function handleSubmit(values, {setSubmitting, setErrors}) {


        setFailRegistered(false)
        setFailRegisteredMessage('')

        const url = process.env.REACT_APP_API_URL + 'api/auth/v1/sign-in';

        const formData = new FormData();

        Object.keys(values).forEach((i) => {
            formData.append(i, values[i]);
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {

            axios.post(url, formData, config).then(async (response) => {

                if (response.data.success === true) {

                    localStorage.setItem('userToken', response.data.token);

                    dispatch(setUserData(response.data.data))

                    goToHome()

                } else {


                    if (response.data.errors.length) {

                        let errors = {}
                        await response.data.errors.forEach(i => {
                            errors[i.path] = i.msg

                        })
                        setErrors(errors)
                    } else if (response.data.message.trim().length) {
                        setFailRegistered(true)
                        setFailRegisteredMessage(response.data.message)
                    }


                }
            }).catch(error => {

                try {

                    if (error.response.data.errors.length) {
                        errorHelper.getErrors(error.response.data.errors).then(errors => setErrors(errors))
                    }
                } catch (e) {

                }
            })

        } catch (e) {
            console.log('Error:' + e.message)
        }
    }

    return (
        <div className='auth-content'>
            <h2 className='text-center'>Sign In</h2>

            <div className="auth-form">

                <div className="row">
                    {failRegistered ? (
                        <>
                            <div className='error-message'>{failRegisteredMessage}</div>
                        </>
                    ) : ('')}
                </div>

                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validate={validate}
                >
                    <Form>
                        <div className="row">
                            <label htmlFor="email">Email</label>
                            <Field type="text" name="email" id='email' placeholder="Email"/>
                            <ErrorMessage name="email" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <label htmlFor="password">Password</label>
                            <Field type="password" name="password" id='password' placeholder="Password"/>
                            <ErrorMessage name="password" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <button className='btn mt-10'>Login</button>
                        </div>
                    </Form>
                </Formik>
            </div>

        </div>
    )

}

export default SignIn