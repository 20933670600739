const userReducer = (state = {username: ''}, action) => {
    switch (action.type) {
        case 'SET_USERDATA':
            return {userData: action.payload};
        case 'UNSET_USERDATA':
            return {userData: action.payload};
        default:
            return state;
    }
};

export default userReducer;
