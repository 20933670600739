import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import ErrorHelper from "../../helpers/ErrorHelper";
import axios from "axios";
import {setUserData} from "../../redux/actions";
import {ErrorMessage, Field, Form, Formik} from "formik";

function SignUp() {

    const errorHelper = new ErrorHelper();

    const [successRegistered, setSuccessRegistered] = useState(false)
    const [successRegisteredMessage, setsuccessRegisteredMessage] = useState('')

    const [failRegistered, setFailRegistered] = useState(false)
    const [failRegisteredMessage, setFailRegisteredMessage] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const initialValues = {
        nikname: '',
        email: '',
        password: '',
        password_conf: '',
    }

    const goToHome = () => {
        // Use navigate to redirect to a different route
        navigate('/');
    };

    const validate = values => {

        const errors = {};

                if (!values.nikname) {
                    errors.nikname = 'The field Nikname is required!';
                }

                if (!values.email) {
                    errors.email = 'The field Email is required!';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                    errors.email = 'Wrong email format!';
                }

                if (!values.password) {
                    errors.password = 'The field Password is required!';
                }


                if (!values.password_conf) {
                    errors.password_conf = 'The field Password is required!';
                }

                if (values.password && values.password_conf && values.password !== values.password_conf) {
                    errors.password_conf = 'Password and Confirm Password not match!';
                }

        return errors;
    };

    function handleSubmit(values, {setSubmitting, setErrors}) {


        setFailRegistered(false)
        setFailRegisteredMessage('')

        const url = process.env.REACT_APP_API_URL + 'api/auth/v1/sign-up';

        const formData = new FormData();

        Object.keys(values).forEach((i) => {
            formData.append(i, values[i]);
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {

            axios.post(url, formData, config).then(async (response) => {

                if (response.data.success === true) {

                    localStorage.setItem('userToken', response.data.token);
                    dispatch(setUserData(response.data.data))
                    // goToHome()

                    setSuccessRegistered(true)
                    setsuccessRegisteredMessage(response.data.message)

                } else {

                    if (response.data.errors.length) {

                        let errors = {}
                        await response.data.errors.forEach(i => {
                            errors[i.path] = i.msg

                        })
                        setErrors(errors)
                    } else if (response.data.message.trim().length) {
                        setFailRegistered(true)
                        setFailRegisteredMessage(response.data.message)
                    }


                }
            }).catch(error => {

                try {

                    if (error.response.data.errors.length) {
                        errorHelper.getErrors(error.response.data.errors).then(errors => setErrors(errors))

                    } else if (error.response.data.success === false) {
                        setSuccessRegistered(false)
                        setsuccessRegisteredMessage('')

                        setFailRegistered(true)
                        setFailRegisteredMessage(error.response.data.message)
                    }
                } catch (e) {

                }

                // console.log('axios.post: ' + error.message)
            })

        } catch (e) {
            console.log('Error:' + e.message)
        }
    }


    return (
        <div className='auth-content'>
            <h2 className='text-center'>Sign Up</h2>

            <div className="auth-form">
                <div className="row">
                    {failRegistered ? (
                        <>
                            <div className='error-message'>{failRegisteredMessage}</div>
                        </>
                    ) : ('')}
                </div>

                <div className="row">
                    {successRegistered ? (
                        <>
                            <div className='success-message'>{successRegisteredMessage}</div>
                        </>
                    ) : ('')}
                </div>

                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validate={validate}
                >
                    <Form>

                        <div className="row">
                            <label htmlFor="nikname">Nikname</label>
                            <Field type="text" name="nikname" id='nikname' placeholder="nikname"/>
                            <ErrorMessage name="nikname" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <label htmlFor="email">Email</label>
                            <Field type="text" name="email" id='email' placeholder="Email"/>
                            <ErrorMessage name="email" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <label htmlFor="password">Password</label>
                            <Field type="password" name="password" id='password' placeholder="Password"/>
                            <ErrorMessage name="password" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <label htmlFor="password_conf">Password</label>
                            <Field type="password" name="password_conf" id='password_conf'
                                   placeholder="Password confirmation"/>
                            <ErrorMessage name="password_conf" component="div" className='error-msg-row mt-5'/>
                        </div>

                        <div className="row">
                            <button className='btn mt-10'>Register</button>
                        </div>

                    </Form>
                </Formik>
            </div>
        </div>

    )

}

export default SignUp