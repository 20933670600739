import './Settings.css';
import './App.css';
import './components/game/GameStyle.css';

import MainComponent from "./components/MainComponent";
import {useDispatch} from "react-redux";
import {setUserData} from './redux/actions';
import React, {createContext, useEffect, useState} from "react";

import {Routes, Route, Navigate} from 'react-router-dom';
import GameComponent from "./components/game/GameComponent";
import DataProvider from "./components/DataProvider";

const DataContext = createContext();

function App() {

    const [isLoading, setIsLoading] = useState(true);

    const userData = {
        energy: 88,
        money: 3,
        realMoney: 0,
        products: {
            wheat: 0,
            flour: 0,
            bread: 0
        },
        fileds: [
            {
                id: 1,
                plant: null
            },
            {
                id: 2,
                plant: null
            },
            {
                id: 3,
                plant: null
            },
            {
                id: 4,
                plant: null
            },

        ],
        seeds: [
            {
                type: 'wheat',
                count: 8,
                price: 1,
                takeEnergy: 10,
                img: '/images/icons/wheat-sack.png'
            },
            {
                type: 'cabbage',
                count: 3,
                price: 2,
                takeEnergy: 15,
                img: '/images/icons/cabbage.png'
            }
        ],
        shop: [
            {
                type: 'wheat',
                count: 10,
                price: 1,
                title: 'Wheat',
                img: '/images/icons/wheat-sack.png'
            },
            {
                type: 'cabbage',
                count: 0,
                price: 5,
                title: 'Cabbage',
                img: '/images/icons/cabbage.png'
            }
        ]
    }

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(setUserData(userData))
        setIsLoading(false)


    }, [])


    if (isLoading) {
        return <>Loading...</>
    }

    return (
        <div className="App">
            <DataProvider DataContext={DataContext}>
                <Routes>
                    <Route path="/" element={<MainComponent/>}/>
                    <Route path="/game" element={<GameComponent DataContext={DataContext}/>}/>
                </Routes>
            </DataProvider>
        </div>
    );
}

export default App;
