import GameHeaderComponent from "./partial/GameHeaderComponent";
import GameWrapperComponent from "./GameWrapperComponent";


function GameComponent({DataContext}) {

    return (
        <div className="game-box">

            <GameHeaderComponent DataContext={DataContext}/>

            <GameWrapperComponent DataContext={DataContext}/>

        </div>
    )

}

export default GameComponent