import {useEffect, useState} from "react";

const FieldItem = ({ele, userData}) => {

    const [fieldItem, setFieldItem] = useState(ele)

    const [isShowFieldOptions, setIsShowFieldOptions] = useState(false);

    useEffect(() => {
        setIsShowFieldOptions(false)
    }, [fieldItem]);


    const seedPlant = (seed) => {

        setIsShowFieldOptions(false)
        if (fieldItem.plant === null) {
            setFieldItem(prevField => ({
                ...prevField, plant: {
                    img: '/images/icons/seed-phase-1.png',
                    step: 0,
                    timer: [
                        {
                            step: 0,
                            time: 100
                        },
                        {
                            step: 1,
                            time: 50
                        },
                        {
                            step: 2,
                            time: 80
                        },
                        {
                            step: 3,
                            time: 100
                        }
                    ]
                }
            }));
        }


    }

    let fieldElement = ""

    if (fieldItem.plant !== null) {
        fieldElement = (
            <>
                <img src={fieldItem.plant.img} alt="" className='seeded-plant'/>
                <span className='field-timer'></span>
            </>
        )
    } else {
        fieldElement = (
            <></>
        )
    }

    return (

        <div className='field-item' onClick={() => {
            if (fieldItem.plant === null) {
                setIsShowFieldOptions(true)
            }
        }}>

            {fieldElement}
            <div className="field-options" style={{display: (isShowFieldOptions === true ? 'grid' : 'none')}}>
                {userData.seeds.map((seed, index) => {
                    return (
                        <div className="field-options-item" onClick={() => seedPlant(seed)} key={index}>
                            <img src={seed.img} alt=""/>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}


export default FieldItem