import {configureStore} from '@reduxjs/toolkit'
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        settings: settingsReducer
    },
});

export default store;
