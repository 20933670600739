export const increment = () => ({
    type: 'INCREMENT',
});

export const decrement = () => ({
    type: 'DECREMENT',
});

export const setUserData = (userData) => ({
    type: 'SET_USERDATA',
    payload: userData,
});
export const unsetUserData = () => ({
    type: 'UNSET_USERDATA',
    payload: null,
});

export const setSettingsData = (settings) => ({
    type: 'SET_SETTINGS',
    payload: settings,
});
export const unsetSettingsData = () => ({
    type: 'UNSET_SETTINGS',
    payload: null,
});
