const settingsReducer = (state = {username: ''}, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return {settingsData: action.payload};
        case 'UNSET_SETTINGS':
            return {settingsData: action.payload};
        default:
            return state;
    }
};

export default settingsReducer;
