import './Tab.css'
import {useState} from "react";

function TabComponent({tabStatus}) {

    const [isLogin, setIsLogin] = useState(true);

    return (
        <div className='tabs-ik'>
            <div className={"tab-ik " + (isLogin ? 'active' : '')} onClick={() => {
                setIsLogin(true)
                tabStatus(true)
            }}><span>Sign In</span></div>
            <div className={"tab-ik " + (!isLogin ? 'active' : '')} onClick={() => {
                setIsLogin(false)
                tabStatus(false)
            }}><span>Sign Up</span></div>
        </div>
    )

}

export default TabComponent;