import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import {BrowserRouter} from "react-router-dom";

import {SocketProvider} from 'socket.io-react';
import {io} from 'socket.io-client';


import {Provider} from 'react-redux';
import store from './redux/store';

const socket = io('http://127.0.0.1:3000', {
    transportOptions: {
        polling: {
            extraHeaders: {
                'Authorization': 'Bearer 1529af75b09eca492eea6ad80167f8e1e8ae4f7f',
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
    <SocketProvider socket={socket}>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </SocketProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
