import {useEffect, useState} from "react";

const ShopItem = ({ele, buySeed = f => f}) => {

    const [seed, setSeed] = useState(ele)


    return (
        <div className='shop-item'>
            <h4>
                <div className='shop-item-title'>{seed.title} - {seed.price} <img
                    src="/images/icons/game-money.png" alt="" style={{height: '20px'}}/></div>

            </h4>
            <img src={seed.img} alt="" className='image'/>
            <div>In stock: {seed.count}</div>
            <button className='btn btn-info' onClick={() => {
                buySeed(seed,setSeed)
            }}>Buy
            </button>
        </div>
    )

}


export default ShopItem