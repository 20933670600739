import {useContext, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

function GameHeaderComponent({DataContext}) {

    const {userDataC, setUserDataC} = useContext(DataContext);



    const userData = useSelector((state) => state.user.userData);

    const energyRef = useRef(userData.energy);

    // const updateEnergyInterval = 1000 * 10;
    const updateEnergyInterval = 1000;

    useEffect(() => {
        energyRef.current = userDataC;
    })

    useEffect(() => {
        setInterval(addEnergy, updateEnergyInterval)
    }, []);


    const addEnergy = () => {

        const latestData = energyRef.current;

        if (latestData.energy < 100) {

            setUserDataC(prevUserDataC => ({...prevUserDataC, energy: prevUserDataC.energy + 1}));
        }
    }

    return (
        <div className="game-box-header">

            <div className="game-box-header-item">

                <img src="/images/icons/energy.png" alt=""/>
                <span className='value'>{userDataC.energy}</span>

            </div>

            <div className="game-box-header-item">

                <img src="/images/icons/game-money.png" alt=""/>
                <span className='value'>{userDataC.money}</span>

            </div>

            <div className="game-box-header-item">

                <img src="/images/icons/real-money.png" alt=""/>
                <span className='value'>{userDataC.realMoney}</span>

            </div>


        </div>
    )

}

export default GameHeaderComponent