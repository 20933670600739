import HomeComponent from "./HomeComponent";

function MainComponent() {

    return (
        <HomeComponent/>
    )

}

export default MainComponent