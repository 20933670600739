import React, {useEffect, useState} from "react";
import TabComponent from "./tabs/TabComponent";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";

const HomeComponent = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        setIsLoading(false)
    }, []);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <h1 className='text-center mb-30 mt-20 flex flex-center'>
                <img src="/images/game-logo-2.png" alt="" className='game-home-logo'/>
                Welcome to economy farm game
                <img src="/images/game-logo-2.png" alt="" className='game-home-logo'/>
            </h1>


            <TabComponent tabStatus={status => {
                setIsLogin(status)
            }}/>

            {isLogin ? <SignIn/> : <SignUp/>}

        </>
    )
}

export default HomeComponent;