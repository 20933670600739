class ErrorHelper {

    async getErrors(errors) {

        return new Promise(async (resolve, reject) => {

            let tmpErrors = [];

            await errors.forEach(i => {
                tmpErrors[i.param] = i.msg
            })
            resolve(tmpErrors)
        })
    }

}

export default ErrorHelper;