import React, {createContext, useState} from "react";
import {useSelector} from "react-redux";


const DataProvider = ({children, DataContext}) => {

    const userDataRedux = useSelector((state) => state.user.userData);

    const [userEnergy, setUserEnergy] = useState(0);
    const [userDataC, setUserDataC] = useState(userDataRedux);

    return (
        <DataContext.Provider value={{userEnergy, setUserEnergy,userDataC, setUserDataC}}>
            {children}
        </DataContext.Provider>
    );

}

export default DataProvider